<template>
  <div class="flex flex-col lg:flex-row min-h-full">
    <div
      class="flex-shrink-0 bg-gray-700 text-white relative"
      :style="$breakpoint.lgAndUp ? 'width: 220px' : 'width: 100%'"
    >
      <div class="sm:px-2 sm:py-4">
        <div class="flex items-center justify-between px-2">
          <UtilLogo lightIcon class="text-gray-300 text-xl" />

          <UtilButton
            v-if="$breakpoint.mdAndDown"
            @click="isMobileMenuVisible = !isMobileMenuVisible"
            color="white"
            textLink
          >
            <FontAwesomeIcon :icon="faBars" fixed-width />
            <span>Menu</span>
          </UtilButton>
        </div>

        <div v-if="isMobileMenuVisible || $breakpoint.lgAndUp" class="mt-6">
          <RouterLink
            :to="item.to"
            v-for="item in compMenu"
            :key="item.to"
            class="nav-item mb-1 block px-2 py-2 mx-2 sm:mx-0 rounded hover:bg-gray-600"
            :class="{ 'bg-gray-800': $route.name.includes(item.routeName) }"
          >
            <FontAwesomeIcon :icon="item.icon" fixedWidth class="mr-2" />
            {{ item.label }}
          </RouterLink>
        </div>
      </div>

      <div
        v-if="isMobileMenuVisible || $breakpoint.lgAndUp"
        class="border-t border-gray-600 flex items-center justify-between lg:fixed lg:bottom-0 px-5 py-4"
        :style="$breakpoint.lgAndUp ? 'width: 220px' : ''"
      >
        <div class="flex items-center">
          <UtilMyAvatar class="h-12 mr-2" />

          <div class="leading-tight">
            <p class="text-gray-300">{{ app.user.name }}</p>

            <UtilTextLink
              @click="$store.dispatch('auth/logout')"
              colorClass="text-brand-lighter"
              class="underline text-sm"
            >
              Logout
            </UtilTextLink>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex-auto w-full relative px-2 lg:px-6 py-3"
      :class="[$route.meta.backgroundClass || 'bg-gray-200']"
    >
      <h1 v-if="$route.meta.title && !$route.meta.hideTitle" class="font-medium text-3xl mb-2">
        {{ $route.meta.title }}
      </h1>

      <slot />
    </div>
  </div>
</template>

<script>
import { faTruck, faSeedling, faBars, faChartNetwork } from '@fortawesome/pro-regular-svg-icons'
import UtilLogo from '@/components/utils/UtilLogo'
import UtilButton from '@/components/utils/UtilButton'
import UtilTextLink from '@/components/utils/UtilTextLink'
import UtilMyAvatar from '@/components/utils/UtilMyAvatar.vue'

export default {
  name: 'LayoutShell',
  components: {
    UtilLogo,
    UtilButton,
    UtilTextLink,
    UtilMyAvatar
  },
  props: {
    title: String
  },
  data() {
    return {
      isMobileMenuVisible: false,
      faTruck,
      faSeedling,
      faBars,
      faChartNetwork
    }
  },
  watch: {
    $route() {
      this.isMobileMenuVisible = false
    }
  },
  computed: {
    compMenu() {
      let items = [
        {
          label: 'Leads',
          routeName: 'leads',
          icon: faSeedling,
          to: '/',
          active: true
        },
        {
          label: 'Haulage',
          routeName: 'haulage',
          icon: faTruck,
          to: '/haulage',
          active: true
        },
        {
          label: 'Insights',
          routeName: 'insights',
          icon: faChartNetwork,
          to: '/insights',
          active: true
        }
      ]

      return items.filter(item => item.active)
    }
  }
}
</script>
