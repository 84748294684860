<template>
  <LayoutAuthPane>
    <div class="text-2xl">
      <FontAwesomeIcon :icon="faSpinnerThird" spin fixedWidth class="mr-2" />
      <span class="text-xl">Authenticating</span>
    </div>
  </LayoutAuthPane>
</template>

<script>
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import LayoutAuthPane from '@/components/layout/LayoutAuthPane'

export default {
  name: 'Authenticating',
  components: {
    LayoutAuthPane
  },
  data() {
    return {
      faSpinnerThird
    }
  },
  watch: {
    'appIs.auth'(newValue) {
      if (newValue === true && this.$route.name === 'auth-redirect') {
        this.$router.replace('/')
      }
    },

    '$route.path'() {
      this.$log.debug('Route changed')

      this.actionAuthTokens()
    }
  },
  methods: {
    async actionAuthTokens() {
      // Have we arrived via an auth portal and does the URL contain an access token for us?
      if (this.$route.query.token) {
        this.$log.debug('Token received')

        this.$store.commit('auth/setAccessToken', this.$route.query.token)
        this.$store.commit('auth/setRefreshToken', this.$route.query.refresh)

        if (this.$route.query.path) {
          this.$router.push(this.$route.query.path).catch(() => {})
        }

        this.$store.dispatch('app/get')
      }
    }
  }
}
</script>
