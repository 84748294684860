import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendarFormat from 'dayjs/plugin/calendar'
import isBetween from 'dayjs/plugin/isBetween'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#relativetime
dayjs.extend(relativeTime)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#localizedFormat
dayjs.extend(LocalizedFormat)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#customparseformat
dayjs.extend(customParseFormat)

// DOCS: https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(advancedFormat)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#calendar
dayjs.extend(calendarFormat)

// DOCS: https://day.js.org/docs/en/plugin/is-between
dayjs.extend(isBetween)

// DOCS: https://day.js.org/docs/en/plugin/quarter-of-year
dayjs.extend(quarterOfYear)

dayjs.locale('en-gb')

const dateFormatter = {
  install(vm) {
    vm.prototype.$date = dayjs

    vm.prototype.$date.legacyFormat = 'DD-MM-YYYY HH:mm:ss'
    vm.prototype.$date.legacyFormatDateOnly = 'DD-MM-YYYY'
    vm.prototype.$date.legacyFormatDateOnlyReverse = 'YYYY-MM-DD'

    vm.prototype.$date.relative = dateString => {
      return dayjs(dateString).calendar(null, {
        sameDay: '[Today] [at] HH:mm', // The same day ( Today at 2:30 AM )
        nextDay: '[Tomorrow] [at] HH:mm', // The next day ( Tomorrow at 2:30 AM )
        nextWeek: 'llll', // The next week ( Sunday at 2:30 AM )
        lastDay: '[Yesterday] [at] HH:mm', // The day before ( Yesterday at 2:30 AM )
        lastWeek: 'llll', // Last week ( Last Monday at 2:30 AM )
        sameElse: 'llll' // Everything else ( 7/10/2011 )
      })
    }
  }
}

Vue.use(dateFormatter)
