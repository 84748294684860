const authRoutes = [
  {
    name: 'auth-redirect',
    path: '/auth-redirect',
    meta: {
      title: 'Authenticating'
    },
    component: () => import(/* webpackChunkName: "Leads" */ '@/views/leads/Leads.vue')
  }
]

export default authRoutes
