import { render, staticRenderFns } from "./UtilTextLink.vue?vue&type=template&id=0e248242&scoped=true&"
import script from "./UtilTextLink.vue?vue&type=script&lang=js&"
export * from "./UtilTextLink.vue?vue&type=script&lang=js&"
import style0 from "./UtilTextLink.vue?vue&type=style&index=0&id=0e248242&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e248242",
  null
  
)

export default component.exports