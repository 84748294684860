import Vue from 'vue'
import Api from '@/lib/api'
import store from '@/store'
import appVersion from '@/lib/app-version'

const slack = {
  install(vm) {
    vm.prototype.$slack = {
      sendMessage: async (message, channels = ['sml-activity']) => {
        let app = store.getters['app/getApp']
        let appIs = store.getters['app/appIs']

        const userUrl =
          app.user && app.user.userRef
            ? `<${process.env.VUE_APP_LEGACY_API_BASE_URL}/admin/user.php?ref=${app.user.userRef}|${app.user.firstName} ${app.user.lastName}>`
            : 'Guest'

        const userPrefix = appIs.suspicious ? ' *SUSPICIOUS USER* - ' : ''

        if (appIs.suspicious) {
          channels.push('fraud')
        }

        let messagePrefix = `v${appVersion} -${userPrefix} ${userUrl} - `

        let combinedMessage = messagePrefix + message

        // If local then override channel to bot-test, but indicate what channels it would have gone to
        if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
          combinedMessage = `${combinedMessage} [${channels.join(',')}]`

          channels = ['bot-test']
        }

        const notifyApi = new Api({
          message: combinedMessage,
          channels
        })

        await notifyApi.post('/notify/v1/slack')
      }
    }
  }
}

Vue.use(slack)
