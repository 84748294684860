<template>
  <div class="flex flex-col justify-center items-center bg-white rounded shadow">
    <UtilLogo class="text-5xl sm:text-6xl border-b border-gray-lighter py-5 px-6 sm:px-20" />

    <div class="my-8">
      <slot />
    </div>
  </div>
</template>

<script>
import UtilLogo from '@/components/utils/UtilLogo'

export default {
  name: 'LayoutAuthPane',
  components: {
    UtilLogo
  }
}
</script>
