import Vue from 'vue'

const mixins = {
  computed: {
    app() {
      return this.$store.getters['app/getApp']
    },

    appIs() {
      return this.$store.getters['app/appIs']
    },

    baseUrls() {
      return {
        adminPortal: process.env.VUE_APP_ADMIN_PORTAL_BASE_URL
      }
    }
  }
}

Vue.mixin(mixins)
