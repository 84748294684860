<template>
  <div
    id="app"
    :class="[`${$breakpoint.classList}`, { isAuth: appIs.auth }, 'flex flex-col h-full relative']"
    :data-breakpoint="$mq"
  >
    <div class="flex-auto">
      <LayoutFloatingNotifications />

      <div
        v-if="compIsAppLoading || compIsAuthenticating || !appIs.auth"
        class="h-screen bg-gray-200 flex flex-col justify-center items-center"
      >
        <Authenticating v-if="compIsAppLoading || compIsAuthenticating" />

        <Login v-else-if="!appIs.auth" />
      </div>

      <template v-else>
        <RouterView v-if="$route.meta.showApplicationShell === false" />

        <LayoutShell v-else>
          <RouterView />
        </LayoutShell>
      </template>
    </div>

    <keep-alive v-for="(modal, index) in $store.state.modal.list" :key="`modal-${modal.modalName}`">
      <component v-if="index === 0" :is="modal.component" v-bind="{ modalData: modal.modalData }" />
    </keep-alive>

    <div id="confirm-portal" />

    <div id="celebration" class="fixed position-center-x bottom-0 z-50 w-1 h-1" />
  </div>
</template>

<script>
import LayoutFloatingNotifications from '@/components/layout/LayoutFloatingNotifications'
import LayoutShell from '@/components/layout/LayoutShell'
import Login from '@/views/Login'
import Authenticating from '@/views/Authenticating'

export default {
  name: 'app',
  components: {
    LayoutFloatingNotifications,
    LayoutShell,
    Login,
    Authenticating
  },
  data() {
    return {
      maintenanceMode: false
    }
  },
  watch: {
    $route() {
      this.$emit('updateHead')
    }
  },
  created() {
    this.initialLoad()
  },
  computed: {
    compIsAppLoading() {
      return this.$store.state.app.routeLoading
    },

    compIsAuthenticating() {
      return (
        this.$store.state.auth.api.isLoading ||
        this.$store.state.app.api.isLoading ||
        this.$route.name === 'auth-redirect'
      )
    }
  },
  methods: {
    // These can happen before the DOM is ready and mounted
    async initialLoad() {
      this.$store.dispatch('app/get')
    }
  },
  head: {
    title() {
      return {
        inner: this.$route.meta.title || ''
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>
