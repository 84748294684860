import Vue from 'vue'

const numberFormatter = {
  install(vm) {
    vm.prototype.$numberFormat = (number, config = {}) => {
      return new Intl.NumberFormat('en-GB', config).format(number)
    }
  }
}

Vue.use(numberFormatter)
