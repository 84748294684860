import Vue from 'vue'

import '@/plugins/log'
import '@/plugins/head-meta'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import '@/plugins/font-awesome'
import '@/plugins/computed-mixins'
import '@/plugins/sentry'
import '@/plugins/form-validation'
import '@/plugins/breakpoints'
import '@/plugins/lazy-load'
import '@/plugins/date-format'
import '@/plugins/number-format'
import '@/plugins/notifications'
import '@/plugins/scroll'
import '@/plugins/portal-vue'
import '@/plugins/modal'
import '@/plugins/slack'
import '@/plugins/multi-select'
import '@/plugins/listing-title'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
