import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import store from '@/store'
import log from '@/plugins/log'
import reportRoutes from './reports'
import authRoutes from './auth'

Vue.use(VueRouter)

let routes = [...reportRoutes, ...authRoutes]

// 404 not found
routes.push({
  name: '404',
  path: '*',
  meta: {
    title: 'Page Not Found'
  },
  component: () => import(/* webpackChunkName: "RouteNotFound" */ '@/views/RouteNotFound.vue')
})

const Router = new VueRouter({
  base: process.env.VUE_APP_PUBLIC_PATH || '/',
  mode: 'history',
  routes: routes,

  parseQuery(query) {
    return qs.parse(query)
  },

  stringifyQuery(query) {
    const result = qs.stringify(query)

    return result ? '?' + result : ''
  },

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

Router.beforeEach((to, from, next) => {
  // Set route loading state so we can show spinners if chunks have to be downloaded on slow connections
  store.commit('app/setRouteLoading', true)

  next()
})

Router.beforeResolve((to, from, next) => {
  // Now our chunk has finished loading lets remove the spinner
  store.commit('app/setRouteLoading', false)

  next()
})

Router.afterEach((to, from) => {
  if (to.name !== from.name) {
    if (to.name !== 'logout') {
      // store.dispatch('notifications/getUnreads')
    }

    store.commit('notifications/routeChange')
    store.dispatch('app/routeChange')

    store.dispatch('modal/closeModals')
  }
})

// Global catch for bad navigation
Router.onError(error => {
  log.debug('Routing error', error)
})

export default Router
