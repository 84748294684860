<template>
  <LayoutAuthPane>
    <UtilButton
      @click="$store.dispatch('auth/start')"
      :isLoading="$store.state.auth.isRedirectingToAuthPortal"
      color="primary"
    >
      <span class="flex items-center">
        <img :src="require('@/assets/images/logos/google-icon.png')" class="mr-3 h-6" />
        <span>Sign in with Google</span>
      </span>
    </UtilButton>
  </LayoutAuthPane>
</template>

<script>
import UtilButton from '@/components/utils/UtilButton'
import LayoutAuthPane from '@/components/layout/LayoutAuthPane'

export default {
  name: 'Login',
  components: {
    UtilButton,
    LayoutAuthPane
  }
}
</script>
