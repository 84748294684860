import ApiCore from './core'

class Api extends ApiCore {
  constructor(model, timeout = 30000) {
    // constructor(model, timeout = 500) {
    super(model, timeout)

    this.axios.interceptors.request.use(config => {
      const store = require('@/store').default

      config.baseURL = process.env.VUE_APP_API_BASE_URL
      config.headers = {
        ...config.headers,
        'x-hctr-key': process.env.VUE_APP_API_APPLICATION_KEY,
        'x-hctr-token': store.state.auth.accessToken
      }

      config.data = JSON.stringify(config.data)

      return config
    })
  }
}

export default Api
