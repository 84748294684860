import Vue from 'vue'

const listingTitle = {
  install(vm) {
    vm.prototype.$listingTitle = listingData => {
      let title = ''

      let listing = { ...listingData }

      if (!listing.quantityStart) {
        listing.quantityStart = listing.quantity
      }

      if (!listing.quantity) {
        title += listing.quantityStart
      } else {
        title += listing.quantity
      }

      let listingTitle = listing.title ? listing.title.trim() : ''

      title += ` ${listingTitle}`

      return title
    }
  }
}

Vue.use(listingTitle)
