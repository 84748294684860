var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message py-2 sm:py-3 px-3 sm:px-4",class:{
    'message-error': _vm.type === 'error',
    'message-information': _vm.type === 'information',
    'message-warning': _vm.type === 'warning',
    'message-success': _vm.type === 'success',
    'pr-6': _vm.dismissible,
    'px-4 py-3': _vm.large
  },on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.showIcon)?_c('FontAwesomeIcon',{staticClass:"icon mr-1",attrs:{"icon":_vm.compIcon,"fixed-width":""}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.dismissible)?_c('a',{on:{"click":function($event){return _vm.dismiss()}}},[_c('FontAwesomeIcon',{staticClass:"message-close",attrs:{"icon":_vm.faTimes}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }