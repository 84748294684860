import router from '@/router'
import log from '@/plugins/log'
import Api from '@/lib/api'
import { isEmpty, get } from 'lodash'
import appVersion from '@/lib/app-version'

const initialState = () => {
  return {
    api: new Api(),
    routeLoading: false,
    routeHistory: []
  }
}

const getters = {
  getUser: state => {
    let data = state.api.data

    return get(data, 'admin.profile.email')
      ? {
          id: data.admin._id,
          email: data.admin.profile.email,
          name: data.admin.profile.name,
          firstName: data.admin.profile.given_name,
          lastName: data.admin.profile.family_name,
          avatarUrl: data.admin.profile.picture,
          locale: data.admin.profile.locale
        }
      : {}
  },

  getApp: (state, getters) => {
    let app = {}

    app.user = getters.getUser

    app.version = appVersion

    return app
  },

  appIs: (state, getters, rootState, rootGetters) => {
    let appIs = {}
    let app = getters.getApp

    appIs.firstLoad = state.routeHistory.length < 2
    appIs.loading = state.api.isLoading
    appIs.finishedIntialLoad = !isEmpty(state.api.data)

    appIs.retina = window.devicePixelRatio > 1
    appIs[process.env.VUE_APP_ENVIRONMENT] = true

    appIs.auth = rootGetters['auth/hasTokens'] && typeof app.user.email !== 'undefined'

    // appIs.auth = true

    return appIs
  },

  // Hardcoded for now until API returns them
  admins: state => {
    return get(state.api, 'data.admins', [])
  },

  getAdminFromId: (state, getters) => adminId => {
    return getters['admins'].find(admin => admin._id === adminId) || {}
  },

  // Hardcoded for now until API returns them
  hauliers: () => {
    return [
      {
        label: 'Josh Teal',
        value: 'Josh Teal'
      },
      {
        label: 'John Wright ',
        value: 'John Wright '
      },
      {
        label: 'L. Bennet',
        value: 'L. Bennet'
      },
      {
        label: 'David Williams',
        value: 'David Williams'
      },
      {
        label: 'Thomson',
        value: 'Thomson'
      },
      {
        label: 'Gilders ',
        value: 'Gilders '
      },
      {
        label: 'Andrew Thompson',
        value: 'Andrew Thompson'
      },
      {
        label: 'Geoff Evans ',
        value: 'Geoff Evans '
      },
      {
        label: 'Rob Andrews',
        value: 'Rob Andrews'
      },
      {
        label: 'Shane Bell ',
        value: 'Shane Bell '
      },
      {
        label: 'LE Jones ',
        value: 'LE Jones '
      },
      {
        label: 'K and S livestock',
        value: 'K and S livestock'
      },
      {
        label: 'Brian Fawcett',
        value: 'Brian Fawcett'
      },
      {
        label: 'James Dixon',
        value: 'James Dixon'
      },
      {
        label: 'R W Stewart',
        value: 'R W Stewart'
      },
      {
        label: 'Typhos farm',
        value: 'Typhos farm'
      },
      {
        label: 'Jason Wareham',
        value: 'Jason Wareham'
      },
      {
        label: 'R Swains & Sons Ltd',
        value: 'R Swains & Sons Ltd'
      },
      {
        label: 'David Collins',
        value: 'David Collins'
      },
      {
        label: 'Michael Ellison',
        value: 'Michael Ellison'
      },
      {
        label: 'Luke Ebery',
        value: 'Luke Ebery'
      },
      {
        label: 'T Morgans',
        value: 'T Morgans'
      },
      {
        label: 'Alistair Grey',
        value: 'Alistair Grey'
      },
      {
        label: 'Stuart Standing',
        value: 'Stuart Standing'
      }
    ]
  }
}

const actions = {
  async get({ state, rootGetters }) {
    if (!rootGetters['auth/hasTokens']) {
      return false
    }

    try {
      await state.api.get('/v2/admin')
    } catch (error) {
      // Stub
    }
  },

  routeChange({ commit }) {
    commit('addRouteHistory')
  },

  async resetAllModules({ commit }) {
    commit('reset')
    commit('auth/reset', null, { root: true })
  }
}

const mutations = {
  setState(state, { key, value }) {
    state[key] = value
  },

  setRouteLoading(state, loading) {
    state.routeLoading = loading
  },

  addRouteHistory(state) {
    state.routeHistory.push(router.currentRoute.fullPath)
  },

  reset(state) {
    log.debug('Resetting app module')

    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
