import Api from '@/lib/api'
import { has } from 'lodash'

const initialState = () => {
  return {
    stickyList: [],
    floatingList: [],
    unreadApi: new Api(),
    markReadApi: new Api()
  }
}

const getters = {
  unread: state => {
    return has(state.unreadApi, 'data.unread') ? state.unreadApi.data.unread : { total: 0 }
  },

  events: state => {
    return state.unreadApi.data.events || []
  }
}

const actions = {
  add({ commit }, { message, type = 'success', position = 'sticky', data = null, timeoutMs = 6000 }) {
    const id = Date.now()

    let messageData = {
      message,
      type,
      id,
      data,
      routeChangeCount: 0
    }

    const addMutation = position === 'sticky' ? 'addSticky' : 'addFloating'
    commit(addMutation, messageData)

    if (position === 'floating') {
      setTimeout(() => commit('delete', id), timeoutMs)
    }
  }
}

const mutations = {
  addSticky(state, messageData) {
    state.stickyList.unshift(messageData)
  },

  addFloating(state, messageData) {
    state.floatingList.unshift(messageData)
  },

  // Delete notification by ID
  delete(state, id) {
    state.stickyList = state.stickyList.filter(n => n.id != id)
    state.floatingList = state.floatingList.filter(n => n.id != id)
  },

  // Every time the route changes let's work out if we need to remove old sticky messages
  routeChange(state) {
    state.stickyList = state.stickyList.map(n => {
      n.routeChangeCount++
      return n
    })

    state.stickyList = state.stickyList.filter(n => {
      return n.routeChangeCount < 2
    })
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
