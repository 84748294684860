const reportRoutes = [
  {
    name: 'leads',
    path: '/',
    meta: {
      title: 'Leads',
      hideTitle: true
    },
    component: () => import(/* webpackChunkName: "Leads" */ '@/views/leads/Leads.vue'),
    children: [
      {
        name: 'leads-board',
        path: '/',
        meta: {
          title: 'Leads Board'
        },
        component: () => import(/* webpackChunkName: "Leads" */ '@/views/leads/LeadsBoard.vue')
      },
      {
        name: 'leads-table',
        path: '/leads/table',
        meta: {
          title: 'Leads Table'
        },
        component: () => import(/* webpackChunkName: "Leads" */ '@/views/leads/LeadsTable.vue')
      },
      {
        name: 'leads-overview',
        path: '/leads/overview',
        meta: {
          title: 'Leads Overview'
        },
        component: () => import(/* webpackChunkName: "Leads" */ '@/views/leads/LeadsOverview.vue')
      }
    ]
  },
  {
    name: 'haulage',
    path: '/haulage',
    meta: {
      title: 'Haulage'
    },
    component: () => import(/* webpackChunkName: "Haulage" */ '@/views/haulage/Haulage.vue'),
    children: [
      {
        name: 'haulage-overview',
        path: '/haulage',
        meta: {
          title: 'Haulage Overview'
        },
        component: () => import(/* webpackChunkName: "Haulage" */ '@/views/haulage/HaulageOverview.vue')
      },
      {
        name: 'haulage-hauls',
        path: '/haulage/hauls/:haulId?',
        meta: {
          title: 'Haulage Accepted Hauls'
        },
        component: () => import(/* webpackChunkName: "Haulage" */ '@/views/haulage/HaulageHauls.vue')
      },
      {
        name: 'haulage-estimates',
        path: '/haulage/estimates/:haulId?',
        meta: {
          title: 'Haulage Estimates'
        },
        component: () => import(/* webpackChunkName: "Haulage" */ '@/views/haulage/HaulageEstimates.vue')
      }
    ]
  },
  {
    name: 'insights',
    path: '/insights',
    meta: {
      title: 'Insights'
    },
    component: () => import(/* webpackChunkName: "Insights" */ '@/views/insights/Insights.vue'),
    children: [
      {
        name: 'insights-kpis',
        path: '/insights',
        meta: {
          title: 'Insights KPIs'
        },
        component: () => import(/* webpackChunkName: "InsightsKpis" */ '@/views/insights/InsightsKpis.vue')
      },
      {
        name: 'insights-users',
        path: '/insights/users/:userRef?',
        meta: {
          title: 'Insights Users'
        },
        component: () => import(/* webpackChunkName: "InsightsUsers" */ '@/views/insights/InsightsUsers.vue')
      },
      {
        name: 'insights-listings',
        path: '/insights/listings',
        meta: {
          title: 'Insights Listings'
        },
        component: () => import(/* webpackChunkName: "InsightsListings" */ '@/views/insights/InsightsListings.vue')
      },
      {
        name: 'insights-subscribers',
        path: '/insights/subscribers',
        meta: {
          title: 'Insights Subscribers'
        },
        component: () =>
          import(/* webpackChunkName: "InsightsSubscribers" */ '@/views/insights/InsightsSubscribers.vue')
      }
    ]
  },
  {
    name: 'sandbox',
    path: '/sandbox',
    meta: {
      title: 'Sandbox'
    },
    component: () => import(/* webpackChunkName: "Sandbox" */ '@/views/Sandbox.vue')
  }
]

export default reportRoutes
