import Api from '@/lib/api'

const initialState = () => {
  return {
    users: new Api(),
    listings: new Api()
  }
}

const getters = {
  users: state => {
    return state.users.data
  },

  listings: state => {
    return state.listings.data
  }
}

const actions = {
  getUsers({ state }) {
    state.users.get('http://localhost:3000/local/analytics/v1/kpis/cohorts/users')
  },

  getListings({ state }) {
    state.listings.get('http://localhost:3000/local/analytics/v1/kpis/cohorts/listings')
  }
}

const mutations = {}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
