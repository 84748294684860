var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.to)?_c('RouterLink',{staticClass:"button",class:[
    _vm.compColorClass,
    _vm.compSizeClass,
    {
      disabled: _vm.isLoading || _vm.disabled,
      loading: _vm.isLoading,
      block: _vm.block,
      'button-icon-only': _vm.iconOnly,
      'button-link': _vm.textLink
    }
  ],attrs:{"to":_vm.to,"name":_vm.name},nativeOn:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2):_c('button',{staticClass:"button",class:[
    _vm.compColorClass,
    _vm.compSizeClass,
    {
      disabled: _vm.isLoading || _vm.disabled,
      loading: _vm.isLoading,
      block: _vm.block,
      'button-icon-only': _vm.iconOnly,
      'button-link': _vm.textLink
    }
  ],attrs:{"type":_vm.type,"name":_vm.name},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }