import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required, email, min, max, min_value as minValue, length, digits } from 'vee-validate/dist/rules'
import fileTypes from '@/lib/file-types'
import log from '@/plugins/log'

extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('digits', digits)
extend('minValue', minValue)
extend('length', length)

// Empty radio selections return TRUE, so we need to make sure there's actually a value
// This is untested with a radio that has a "true" option value...
extend('radioRequired', {
  validate: value => {
    if (value === true || value === '') {
      return false
    }

    return true
  }
})

extend('confirmTickbox', {
  validate: value => {
    if (value === true || value === 1) {
      return true
    }

    return '{_field_}'
  }
})

// Our custom file upload
extend('fileUpload', {
  message: 'Unknown file upload error',
  params: ['requiredTypes', 'minFiles', 'maxFiles'],
  validate: (uploadedFiles, { requiredTypes, minFiles, maxFiles }) => {
    let validationErrors = []

    // Check overall counts
    if (uploadedFiles.length < minFiles) {
      const filesRemaining = minFiles - uploadedFiles.length
      validationErrors.push(`Upload ${filesRemaining} more files`)
    }

    if (uploadedFiles.length > maxFiles) {
      const filesToDeleteCount = maxFiles - uploadedFiles.length
      validationErrors.push(`Delete ${filesToDeleteCount} files to continue`)
    }

    // Check files have min/max counts for their type
    if (requiredTypes) {
      requiredTypes.forEach(requirement => {
        let type = requirement.type
        let extensionsForType = fileTypes[type]
        let minRequired = requirement.min || 0
        let maxAllowed = requirement.max

        let typeCount = 0
        uploadedFiles.forEach(file => {
          if (extensionsForType.includes(file.type)) {
            typeCount++
          }
        })

        if (typeCount < minRequired) {
          const remainingCount = minRequired - typeCount
          validationErrors.push(`Upload ${remainingCount} more ${type} files`)
        }

        if (maxAllowed && typeCount > maxAllowed) {
          const deleteCount = maxAllowed - typeCount
          validationErrors.push(`You can only upload ${minRequired} ${type}(s), please delete ${deleteCount}`)
        }
      })
    }

    if (validationErrors.length) {
      log.debug('File upload errors', validationErrors)

      return validationErrors.join('. ')
    }

    return true
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// https://baianat.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
setInteractionMode('eager-change', ({ errors, value }) => {
  if (errors.length) {
    return {
      on: ['input', 'change']
    }
  }

  // If the user hasn't entered a value yet then don't error on blur.
  // This is useful when using autofocus on a modal when the user
  // immediately clicks close modal, we won't want an error to show
  if (!value) {
    return {
      on: ['change']
    }
  } else {
    return {
      on: ['change', 'blur']
    }
  }
})
