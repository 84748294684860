<template>
  <h3 class="font-bold flex items-center">
    <img
      :src="require(`@/assets/images/logos/hectare-icon-${lightIcon ? 'light' : 'dark'}.png`)"
      class="mr-2 icon"
    />

    <span class="block flex-shrink-0 whitespace-no-wrap">Hectare CRM</span>
  </h3>
</template>

<script>
export default {
  name: 'UtilLogo',
  props: {
    lightIcon: Boolean
  }
}
</script>

<style lang="scss" scoped>
.icon {
  height: 1.5em;
}
</style>
