<template>
  <RouterLink
    v-if="to"
    :to="to"
    @click.native="$emit('click')"
    class="text-link cursor-pointer"
    :class="colorClass"
  >
    <slot />
  </RouterLink>

  <a v-else @click="$emit('click')" class="text-link cursor-pointer" :class="colorClass">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'UtilTextLink',
  props: {
    to: {
      type: String
    },
    colorClass: {
      type: String,
      default: 'text-brand'
    }
  }
}
</script>

<style lang="scss" scoped>
.text-link:not(.no-underline) {
  text-decoration: underline;
}

.text-link:hover {
  opacity: 0.8;
}
</style>
