import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app.module'
import auth from './modules/auth.module'
import modal from './modules/modal.module'
import notifications from './modules/notifications.module'
import cohorts from './modules/cohorts.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    modal,
    notifications,
    cohorts
  },
  strict: false
})
