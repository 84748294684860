import { get } from 'lodash'
import log from '@/plugins/log'
import Api from '@/lib/api'
import router from '@/router'

const STORAGE_KEY_ACCESS_TOKEN = 'sml-crm-access-token'
const STORAGE_KEY_REFRESH_TOKEN = 'sml-crm-refresh-token'

const initialState = () => {
  return {
    api: new Api(),
    accessToken: window.localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN),
    refreshToken: window.localStorage.getItem(STORAGE_KEY_REFRESH_TOKEN),
    isRedirectingToAuthPortal: false
  }
}

const getters = {
  hasTokens: state => {
    return state.accessToken !== null && state.refreshToken !== null
  }
}

const actions = {
  // Kick off the SSO Oauth process by redirecting to Google auth portal
  start({ commit }) {
    log.debug('Redirecting to SSO auth portal...')

    commit('setState', { key: 'isRedirectingToAuthPortal', value: true })

    window.location.href = `${process.env.VUE_APP_API_BASE_URL}/v2/auth/login?path=${router.currentRoute.fullPath}&key=${process.env.VUE_APP_API_APPLICATION_KEY}`
  },

  async refreshToken({ state, commit, dispatch }) {
    if (!state.refreshToken) {
      commit('reset')

      return false
    }

    log.debug('Refreshing token')

    try {
      // Use our refresh token to get an updated access token
      await state.api.post('/v2/auth/token', {
        token: state.refreshToken
      })

      if (get(state.api, 'data.token')) {
        commit('setAccessToken', state.api.data.token)

        // Try to load our profile again now we have a new token
        dispatch('app/get', null, { root: true })
      }
    } catch (error) {
      // If our refresh token is no longer valid then give up and reset everything
      if ([401, 403].includes(state.api.responseCode)) {
        commit('reset')
      }
    }
  },

  async logout({ state, dispatch }) {
    // Destroy our sessions server side then locally
    await state.api.post('/v2/auth/logout')

    dispatch('app/resetAllModules', null, { root: true })
  }
}

const mutations = {
  setState(state, { key, value }) {
    state[key] = value
  },

  setAccessToken(state, token) {
    state.accessToken = token
    window.localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, token)
  },

  setRefreshToken(state, token) {
    state.refreshToken = token
    window.localStorage.setItem(STORAGE_KEY_REFRESH_TOKEN, token)
  },

  reset(state) {
    log.debug('Resetting auth module')

    window.localStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN)
    window.localStorage.removeItem(STORAGE_KEY_REFRESH_TOKEN)

    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
